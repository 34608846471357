import styled from 'styled-components'
import theme from '../../themes/default'

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 2rem;
  width: ${({ isArticle }) => isArticle ? '100%' : 'auto'};
  background: ${({ isArticle }) => isArticle ? '#dedede' : theme.moduleBg};
  color: ${({ isArticle }) => isArticle ? '#333' : '#999'};;
  border-radius: 48px;
  font-size: 0.75rem;
  font-weight: 300;
  a {
    color: ${theme.skyBlue};
  }
  @media (max-width: 480px) {
    width: ${({ isArticle }) => isArticle ? '90%' : 'auto'};
    margin: 1rem 0.5rem;
  }
`

const Copyright = styled.p`
  margin: 0;
  padding: 1rem 1.5rem;
`

const IconAttribution = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  padding: 1rem 1.5rem;
`

export default function Footer({ isArticle }) {
  return (
    <Container isArticle={isArticle}>
      <Copyright>© 2025 kevinchuang.dev</Copyright>
      <IconAttribution>
        <a href='https://freeicons.io/'>Icons</a>
        &nbsp;by&nbsp;
        <a href='https://freeicons.io/profile/3'>icon king1</a>
        {
          !isArticle
            ? <><>/</><a href='https://freeicons.io/profile/135331'>Chanut is Industries</a></>
            : ''
        }
      </IconAttribution>
    </Container>
  )
}
